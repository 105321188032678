var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "h-screen flex w-full bg-img" }, [
    _c(
      "div",
      {
        staticClass:
          "vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center"
      },
      [
        _c("img", {
          staticClass: "mx-auto mb-4",
          attrs: {
            src: require("../../assets/images/pages/404.png"),
            alt: "graphic-404"
          }
        }),
        _c("h1", { staticClass: "mb-12 text-5xl d-theme-heading-color" }, [
          _vm._v("Error desconocido")
        ]),
        _c("p", { staticClass: "mb-16 d-theme-text-inverse" }, [
          _vm._v("Por favor, vuelva a intentarlo más tarde")
        ]),
        _c("vs-button", { attrs: { size: "large", to: "/" } }, [
          _vm._v("Volver a Inicio")
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }